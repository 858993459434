'use client';

import { useRouter } from 'next/navigation';

import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  SpinnerIcon,
  TelInput,
  TypographyBalancer,
  TypographyH4,
  TypographyMuted,
  TypographyParagraph,
  TypographyTime,
} from '@/components';
import {
  useCartAction,
  useCheckAuthAction,
  useLoginAction,
  useLoginValue,
} from '@/context';
import { useTimeCountdown } from '@/hooks';
import { sendSigninOtp, signInWithOtp } from '@/services';
import { FormSchema, STORAGE } from '@/utils';

const {
  LOCAL: {
    DEFAULT: {
      LOGIN: {
        OTP: { EXPIRATION_TIME: LOGIN_OTP_EXPIRATION_TIME },
        TERMS: { AGREEMENT: LOGIN_TERMS_AGREEMENT },
      },
    },
  },
} = STORAGE;

const OtpInitialSchema = FormSchema.pick({
  phoneNumber: true,
});
const OtpConfirmSchema = FormSchema.pick({
  otp: true,
});

const LoginOtpExpirationTime = () => {
  const { phoneNumber } = useLoginValue();
  const loginAction = useLoginAction();

  const { seconds, minutes, isRunning, restart } = useTimeCountdown({
    countStart: LOGIN_OTP_EXPIRATION_TIME,
  });
  const { isPending, mutate } = useMutation({
    mutationFn: sendSigninOtp,
    onSuccess: ({ ticket }) => {
      loginAction({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      restart();
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  if (isRunning) {
    return (
      <div className='my-2.5 text-sm'>
        <span>ارسال مجدد کد تا</span>
        <TypographyTime className='mx-0.5 text-primary'>
          {minutes}:{seconds}
        </TypographyTime>
        <span>دیگر</span>
      </div>
    );
  }

  return (
    <Button
      variant='link'
      disabled={isPending}
      onClick={() => {
        if (phoneNumber) {
          mutate({ phoneNumber });
        }
      }}
    >
      {isPending ? (
        <SpinnerIcon className='animate-spin' />
      ) : (
        <span>ارسال مجدد کد</span>
      )}
    </Button>
  );
};

const LoginOtpConfirmForm = () => {
  const { phoneNumber, ticket } = useLoginValue();
  const loginAction = useLoginAction();
  const checkAuthAction = useCheckAuthAction();
  const cartAction = useCartAction();
  const form = useForm<z.infer<typeof OtpConfirmSchema>>({
    resolver: zodResolver(OtpConfirmSchema),
    defaultValues: {
      otp: '',
    },
  });
  const router = useRouter();

  const onSubmit = async ({ otp }: z.infer<typeof OtpConfirmSchema>) => {
    if (!ticket) return;

    try {
      await signInWithOtp({ otp, ticket });

      checkAuthAction({
        type: 'UPDATE_CHECK_AUTH_SUCCESS',
        payload: {
          success: true,
        },
      });
      cartAction({
        type: 'UPDATE_CART_STATUS',
        payload: {
          enabledInitialize: true,
          enabledSync: false,
        },
      });
      toast.success('با موفقیت وارد حساب خود شدید.');
      router.back();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;

  return (
    <div>
      <div className='space-y-2'>
        <TypographyH4 className='max-lg:text-center'>
          کد تایید خود را وارد کنید
        </TypographyH4>
        <TypographyParagraph className='text-sm max-lg:text-center'>
          <span>کد تایید برای شماره</span>
          <bdi className='mx-1 inline-flex font-medium text-primary'>
            {phoneNumber}
          </bdi>
          <span>فرستاده شده است.</span>
          <Button
            variant='link'
            className='mx-1 h-auto p-0 font-medium'
            onClick={() => {
              loginAction({
                type: 'UPDATE_LOGIN_STEP',
                payload: { step: 1 },
              });
            }}
          >
            اصلاح شماره
          </Button>
        </TypographyParagraph>
      </div>
      <Form {...form}>
        <form className='space-y-8' onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name='otp'
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputOTP
                    autoFocus
                    maxLength={6}
                    onComplete={form.handleSubmit(onSubmit)}
                    render={({ slots }) => (
                      <InputOTPGroup className='mx-auto max-w-sm'>
                        {slots.map((slot, index) => (
                          <InputOTPSlot key={index} {...slot} />
                        ))}
                      </InputOTPGroup>
                    )}
                    {...field}
                  />
                </FormControl>
                <div className='grid place-items-center'>
                  <LoginOtpExpirationTime />
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <div className='space-y-2'>
            <Button type='submit' className='w-full' disabled={isSubmitting}>
              {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
              <span>ورود به حساب کاربری</span>
            </Button>
            <TypographyBalancer className='text-center'>
              <TypographyMuted>{LOGIN_TERMS_AGREEMENT}</TypographyMuted>
            </TypographyBalancer>
          </div>
        </form>
      </Form>
    </div>
  );
};

const LoginOtpInitialForm = () => {
  const { phoneNumber = '' } = useLoginValue();
  const loginAction = useLoginAction();
  const form = useForm<z.infer<typeof OtpInitialSchema>>({
    resolver: zodResolver(OtpInitialSchema),
    defaultValues: { phoneNumber },
  });

  const onSubmit = async ({
    phoneNumber,
  }: z.infer<typeof OtpInitialSchema>) => {
    try {
      const { ticket } = await sendSigninOtp({ phoneNumber });

      loginAction({
        type: 'UPDATE_LOGIN_PHONE_NUMBER',
        payload: { phoneNumber },
      });
      loginAction({ type: 'UPDATE_LOGIN_TICKET', payload: { ticket } });
      loginAction({ type: 'UPDATE_LOGIN_STEP', payload: { step: 2 } });
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const {
    formState: { isSubmitting },
  } = form;

  return (
    <div>
      <TypographyH4>ورود یا ثبت نام</TypographyH4>
      <Form {...form}>
        <form className='space-y-8' onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name='phoneNumber'
            render={({ field }) => (
              <FormItem>
                <FormLabel>شماره تلفن همراه</FormLabel>
                <FormControl>
                  <TelInput
                    autoFocus
                    placeholder='لطفا شماره تلفن همراه خود را وارد کنید'
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className='space-y-2'>
            <Button type='submit' className='w-full' disabled={isSubmitting}>
              {isSubmitting ? <SpinnerIcon className='animate-spin' /> : null}
              <span>ادامه</span>
            </Button>
            <TypographyBalancer className='text-center'>
              <TypographyMuted>{LOGIN_TERMS_AGREEMENT}</TypographyMuted>
            </TypographyBalancer>
          </div>
        </form>
      </Form>
    </div>
  );
};

const LoginForm = () => {
  const { step } = useLoginValue();

  if (step === 2) {
    return <LoginOtpConfirmForm />;
  }

  return <LoginOtpInitialForm />;
};

export {
  LoginForm,
  LoginOtpConfirmForm,
  LoginOtpExpirationTime,
  LoginOtpInitialForm,
};
